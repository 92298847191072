import { lazy } from "react";
import { Navigate } from "react-router";
import Loadable from "src/routes/Loadable";

const WhisperDrop = Loadable(
    lazy(() => import("src/pages/whisper-drop/index"))
);

const WalletAddressTable = Loadable(
    lazy(() =>
        import("src/pages/whisper-drop/components/wallet-address-table/index")
    )
);

const AllWalletAddressTable = Loadable(
    lazy(() =>
        import(
            "src/pages/whisper-drop/components/all-wallet-address-table/index"
        )
    )
);

const Tokens = Loadable(
    lazy(() => import("src/pages/whisper-drop/components/tokens-table/index"))
);

const History = Loadable(
    lazy(() => import("src/pages/whisper-drop/components/history/index"))
);

const Settings = Loadable(
    lazy(() => import("src/pages/whisper-drop/components/settings/index"))
);

const Description = Loadable(
    lazy(() => import("src/pages/whisper-drop/components/description/index"))
);

const Help = Loadable(
    lazy(() => import("src/pages/whisper-drop/components/help/index"))
);

const walletAddress = [
    {
        path: "whisper-drop",
        element: <WhisperDrop />,
        children: [
            { index: true, element: <Navigate to='wallets' /> },
            {
                path: "wallets",
                element: <WalletAddressTable />,
            },
            { path: "wallets-all", element: <AllWalletAddressTable /> },

            { path: "tokens", element: <Tokens /> },
            { path: "history", element: <History /> },
            { path: "settings", element: <Settings /> },
            { path: "description", element: <Description /> },
            { path: "help", element: <Help /> },
        ],
    },
];

export default walletAddress;
